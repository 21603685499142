.App {
  text-align: center;
}
.leaflet-container {
  height: calc(100dvh - 74px);
  width: 100vw;
}

.loading {
  background-color: gray;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
}
