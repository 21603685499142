@import url("https://fonts.googleapis.com/css2?family=Darumadrop+One&display=swap");

.top {
  text-align: center;
  height: 100dvh;
  width: 100vw;
}

.nav-brand {
  font-family: "Darumadrop One";
  font-size: 2rem;
}

.offcanvas-btn {
  position: fixed;
  bottom: 70px;
  right: 5px;
  height: auto;
  width: 55px;
  left: 0;
  z-index: 1015;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Darumadrop One";
  line-height: 0.5;
}

.current-position-btn {
  position: fixed;
  bottom: 75px;
  right: 5px;
  height: auto;
  width: 50px;
  z-index: 1015;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Darumadrop One";
  line-height: 0.5;
}

/* PWA環境下でのみ実行する設定を記述 */
@media (display-mode: standalone) {
  .current-position-btn {
    bottom: 60px;
  }
  .offcanvas-btn {
    bottom: 55px;
  }
}

.leaflet-popup-content-wrapper {
  background-color: rgb(33, 37, 41);
}
.leaflet-popup-tip {
  background-color: rgb(33, 37, 41);
}

.modal-content {
  background-color: rgb(33, 37, 41);
}

.modal-footer {
  border-top: none;
}
.modal-header {
  border-bottom: none;
}

.btn-close {
  color: white;
}

.tag-for-search {
  color: rgb(33, 37, 41);
}

/* フッターボタンのスタイル */
/* .footer-btn-container {
  overflow: scroll;
} */
.footer-btn-container::-webkit-scrollbar {
  display: none;
}

/* ポップアップのスクロールバー削除 */
.leaflet-popup-scrolled::-webkit-scrollbar {
  display: none;
}
/* ポップアップのボタン色 */
.leaflet-container a {
  color: #ffffff;
}

.pin-pointer-down {
  display: inline-block;
  vertical-align: middle;
  color: #333;
  line-height: 1;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 0.64952em 0.375em;
  border-top-color: currentColor;
  border-bottom: 0;
  top: 0;
}
/* ピンハイライト用 */

.serched-pin {
  z-index: 100000 !important;
  background-color: red;
  border: solid;
  border-width: 0.1rem;
  border-radius: 30%;
  border-color: red;
}
